/**
 * Applies given styles to a DOM element
 * @param {HTMLElement} el - The DOM element to which styles should be applied
 * @param {Object} styles - An object containing style properties and their values
 */
const styles = (el, styles) => {
  for (const property in styles) {
    el.style[property] = styles[property];
  }
};

/**
 * Hides given DOM elements
 * @param  {...HTMLElement} el - The DOM elements to hide
 */
const hide = (...el) => {
  [...el].forEach((e) => (e.style.display = 'none'));
};

/**
 * Shows given DOM elements
 * @param  {...HTMLElement} el - The DOM elements to show
 */
const show = (...el) => {
  [...el].forEach((e) => (e.style.display = ''));
};

/**
 * Disables given DOM elements
 * @param  {...HTMLElement} el - The DOM elements to disable
 */
const disable = (...el) =>
  [...el].forEach((e) => {
    e.classList.add('-disabled');
    e.readonly = true;
  });

/**
 * Enables given DOM elements
 * @param  {...HTMLElement} el - The DOM elements to enable
 */
const enable = (...el) =>
  [...el].forEach((e) => {
    e.classList.remove('-disabled');
    e.readonly = false;
  });

/**
 * Returns a DOM element if valid, throws TypeError otherwise
 * @param {HTMLElement} element - The DOM element to validate
 * @returns {HTMLElement} - The valid DOM element
 * @throws {TypeError} - If the input is not a valid DOM element
 */
const getElement = (element) => {
  if (element.tagName) {
    return element;
  } else {
    throw new TypeError(
      `${element} is not a valid element. Valid options are: DOM Element.`
    );
  }
};

/**
 * Adds a class to a DOM element
 * @param {HTMLElement} element - The DOM element
 * @param {string} className - The class to add
 */
const addClass = (element, className) => {
  if (element.classList) {
    element.classList.add(className);
  } else {
    element.className += ` ${className}`;
  }
};

/**
 * Removes a class from a DOM element
 * @param {HTMLElement} element - The DOM element
 * @param {string} className - The class to remove
 */
const removeClass = (element, className) => {
  if (element.classList) {
    element.classList.remove(className);
  } else {
    const re = new RegExp(
      '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
      'gi'
    );
    element.className = element.className.replace(re, ' ');
  }
};

/**
 * Checks if a DOM element is visible in the viewport
 * @param {HTMLElement} el - The DOM element to check
 * @param {boolean} [partiallyVisible=false] - If true, checks if element is partially visible
 * @returns {boolean} - True if the element is (partially) visible, false otherwise
 */
const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

/**
 * Gets a CSS rule value of a DOM element
 * @param {HTMLElement} el - The DOM element
 * @param {string} ruleName - The name of the CSS rule
 * @returns {string} - The CSS rule value
 */
const getStyle = (el, ruleName) => getComputedStyle(el)[ruleName];

/**
 * Toggles a class for a DOM element
 * @param {HTMLElement} el - The DOM element
 * @param {string} className - The class to toggle
 */
const toggleClass = (el, className) => el.classList.toggle(className);

/**
 * Creates a DOM element from a string
 * @param {string} str - The string to convert into a DOM element
 * @returns {Node} - The DOM element
 */
const createElement = (str) => {
  const el = document.createElement('div');
  el.innerHTML = str;
  return el.firstElementChild;
};

/**
 * Checks if a target has a parent with a specific class
 * @param {Node} target - The target DOM node
 * @param {string} className - The class to look for
 * @returns {boolean} - True if a parent with the specified class is found, false otherwise
 */
const hasParentWithMatchingSelector = (target, className) => {
  return [...document.querySelectorAll(className)].some(
    (el) => el !== target && el.contains(target)
  );
};

/**
 * Adds an event listener to a DOM element with optional delegation and options
 * @param {HTMLElement} el - The DOM element
 * @param {string} evt - The event type
 * @param {Function} fn - The listener function
 * @param {Object} [opts={}] - Optional options for event delegation and listener options
 * @returns {Function} - The delegator function if target is specified in options, undefined otherwise
 */
const on = (el, evt, fn, opts = {}) => {
  const delegatorFn = (e) =>
    e.target.matches(opts.target) && fn.call(e.target, e);
  el.addEventListener(
    evt,
    opts.target ? delegatorFn : fn,
    opts.options || false
  );
  if (opts.target) return delegatorFn;
};

/**
 * Removes an event listener from a DOM element
 * @param {HTMLElement} el - The DOM element
 * @param {string} evt - The event type
 * @param {Function} fn - The listener function
 * @param {Object|boolean} [opts=false] - Optional options for the event listener
 */
const off = (el, evt, fn, opts = false) =>
  el.removeEventListener(evt, fn, opts);

/**
 * Toggles a class for multiple DOM elements
 * @param {string} className - The class to toggle
 * @param  {...HTMLElement} el - The DOM elements
 */
const toggleClasses = (className, ...el) =>
  [...el].forEach((e) => e.classList.toggle(className));

/**
 * Creates and returns a debounced function that delays invoking the input function until after wait milliseconds have elapsed since the last time the debounced function was invoked
 * @param {Function} fn - The function to debounce
 * @param {number} [ms=0] - The number of milliseconds to delay
 * @returns {Function} - The debounced function
 */
const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

/**
 * Copies a string to the clipboard
 * @param {string} str - The string to copy to the clipboard
 */
const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export {
  styles,
  getStyle,
  hide,
  show,
  disable,
  enable,
  getElement,
  addClass,
  removeClass,
  elementIsVisibleInViewport,
  toggleClass,
  createElement,
  hasParentWithMatchingSelector,
  on,
  off,
  toggleClasses,
  debounce,
  copyToClipboard
};
